<!--
 * @Author: your name
 * @Date: 2020-04-23 16:12:30
 * @LastEditTime: 2020-12-03 10:00:00
 * @LastEditors: Please set LastEditors
 * @Description: In User Settings Edit
 * @FilePath: /cunw-youzhiduo-OS/src/views/useHelp/index.vue
-->

<template>
  <div class="preview-news">
    <div class="main">
      <div class="left">
        <h3>中央媒体“长沙高质量发展调研行”启动</h3>
        <div class="news-setting">
          <h3 class="time">
            发布时间： 2019-04-28
          </h3>
          <!-- <h5 class="num">
            浏览量： 28289
          </h5> -->
        </div>
        <div class="img-box" style="margin-top:40px">
          <img src="../../assets/images/news/news11/news1.png" alt="">
          <!-- <img src="../../assets/images/news/news11/news2.png" alt="" style="margin-top:20px">
          <img src="../../assets/images/news/news11/news3.png" alt="" style="margin-top:20px">
          <img src="../../assets/images/news/news11/news4.png" alt="" style="margin-top:20px"> -->
        </div>
        <div class="img-box" style="margin-top:20px">
          <img src="../../assets/images/news/news11/news2.png" alt="">
        </div>
        <div class="img-box" style="margin-top:20px">
          <img src="../../assets/images/news/news11/news3.png" alt="">
        </div>
        <div class="img-box" style="margin-top:20px">
          <img src="../../assets/images/news/news11/news4.png" alt="">
        </div>
        <div class="news-content" style="margin:20px 0 40px">
          <p style="text-align:justify;">
            今天，中宣部组织的中央媒体来湘，“壮丽70年，奋斗新时代，推动高质量发展调研行”主题采访活动正式启动。人民日报，新华社等12家央媒的三十多名记者将在接下来的三天里，对长沙的文化、科技、产业等全方位深入采访。
          </p>
          <p style="text-align:justify;">
            本次主题采访活动将走访湘江新区、长沙高新区、长沙经开区以及以岳麓山国家大学科技城，马栏山视频文创产业园为代表的重点片区。用笔墨镜头突显长沙高质量发展的城市品质。
          </p>
          <p style="text-align:justify;">
            位于岳麓山国家大学科技城的新云网科技有限公司与中南大学建立了产学研战略合作关系。去年九月，湖南成为全国首个教育信息化2.0试点省份，新云网结合教育大数据、人工智能和智慧物联三大技术，打造出教育资源、智慧课堂、在线学习、教育管理四个层面的运用，推动了教育信息化的快速发展。
          </p>
          <p style="text-align:justify;">
            新云网打造的这种智慧教室可以通过智慧黑板，智慧课桌等信息终端，让抽象生硬的知识变得简单易懂，大大提升了学习效率和师生互动。
          </p>

          <p style="text-align:justify;">
            近年来长沙按照省委省政府“创新引领 开放学习”战略，狠抓实体经济，发力产业项目。岳麓山国家大学科技城，马栏山视频文创产业园等重点片区高起点规划，大手笔建设。在项目孵化、产业培育、创新创业等方面取得了瞩目的成就，长沙呈现了高质量发展的喜人态势。
          </p>
        </div>

        <div class="pagination">
          <div class="a-more">
            <span @click="$router.push('/indexOs/newsDetails10')">上一条：两山”崛起“头脑产业” —长沙高质量发展调研行</span>
            <span @click="$router.push('/indexOs/newsDetails12')">下一条：透明计算应用系列成果再出智慧医疗和智慧教育新品， 5G技术将催生更多应用场景</span>
          </div>
          <el-button plain @click="backGo()">
            返回
          </el-button>
        </div>
      </div>
      <!-- 搜索区域 -->
      <!-- <div class="search-right">
        <el-input
          v-model="keyWord"
          placeholder="查询你喜欢的内容"
          suffix-icon="el-icon-search"
        />
        <div class="hot-news">
          <div class="hot-title">
            <span>最热文章</span>
            <i class="hot-icon" />
          </div>
          <div class="lb-news">
            <router-link class="dg-news" to="/indexOs/newsDetails1">
              <img src="../../assets/images/news/news2-1.png">
              <div class="news-introduce">
                <h3 class="news-title">
                  中共中央政治局常委、国务院副总理 韩正来我公司视察
                </h3>
                <h5 class="time">
                 2020-06-01
                </h5>
              </div>
            </router-link>
            <router-link class="dg-news" to="/indexOs/newsDetails2">
              <img src="../../assets/images/news/news2-2.png">
              <div class="news-introduce">
                <h3 class="news-title">
                    岳麓山大学科技城：强化科技人才支撑 打造湖南创新...
                </h3>
                <h5 class="time">
                  2020-06-03
                </h5>
              </div>
            </router-link>
            <router-link class="dg-news" to="/indexOs/newsDetails3">
              <img src="../../assets/images/news/news2-3.png">
              <div class="news-introduce">
                <h3 class="news-title">
                  大科城软件产业联盟拍了拍
                </h3>
                <h5 class="time">
                  2020-08-03
                </h5>
              </div>
            </router-link>
          </div>
        </div>
      </div> -->
    </div>
  </div>
</template>

<script>
export default {
  name: 'PreviewNews',
  data() {
    return {
      keyWord: ''
    };
  },
  methods: {
    backGo() {
      this.$router.go(-1);
    }
  }
};
</script>

<style scoped lang="scss">
@media screen and (min-width: 1400px) {
.preview-news{
  width: 100%;
  height: auto;
  background: #F5F5F5;
  .main{
    width: 800px;
    height: auto;
    margin: 0 auto;
    display: flex;
    padding-top: 58px;
    justify-content: space-between;
    .left{
      flex: 1;
      margin-right: 63px;
      h3{
        color: #313131;
        font-size:24px;
        font-weight: bolder;
        text-align: center;
      }
      .news-setting{
        color: #B5B5B5;
        display: flex;
         font-size:18px;
        .time{
          font-size:18px;
          color: #B5B5B5;
          font-weight: 100;
          width: 100%;
          text-align: center;
        }
        .num{
          font-size:18px;
          margin-left: 30px;
          font-weight: 100;
        }
      }
      .news-content{
        p{
          font-size: 18px;
          color: #313131;
          line-height: 32px;
          text-indent: 36px;
        }
        .img-explain{
          text-align:justify;
          text-align: center;
          font-size:16px;
        }
      }
      .img-box{
        width: 100%;
    display: flex;
    justify-content: center;
        img{
          width:800px;
        }
      }
    }
    .pagination{
      display: flex;
      justify-content: space-between;
      padding: 50px 0 150px;
      border-top: 1px solid #DDDDDD;
      .a-more{
        display: flex;
        flex-direction: column;
        font-size:18px;
        line-height:32px;
        color:rgba(49,49,49,1);
        span{
          display: inline-block;
          width:600px;
          white-space: nowrap;
          text-overflow:ellipsis;
          overflow: hidden;
          cursor: pointer;
        }
      }
      .el-button{
        width: 134px;
        height: 48px;
        border: 1px solid rgba(253,156,2,1);
        opacity: 1;
        border-radius: 10px;
        font-size:24px;
        color:rgba(253,156,2,1);
      }
    }
    // 搜索区域
    .search-right{
      width: 334px;
      .el-input{
        width: 329px;
        height: 45px;
      }
      .el-input__inner{
        border-radius: 5px;
      }
      .el-input__icon{
        font-size: 24px;
      }
      .hot-news{
        .hot-title{
          font-size:24px;
          margin-bottom: 12px;
          color:#313131;
          .hot-icon{
            display: inline-block;
            background: url('../../assets/images/news/hot.png');
            width: 18px;
            height: 22px;
            margin-left: 6px;
          }
        }
        .dg-news{
          display: flex;
          margin-bottom: 22px;
          img{
            width:132px;
            height:76px;
          }
          .news-introduce{
            flex: 1;
            margin-left: 8px;
            display: flex;
            flex-direction: column;
            justify-content: space-between;

            .news-title{
              font-size:16px;
              font-weight: 600;
              color: #313131;
              box-sizing: border-box;
              text-overflow:ellipsis;
              display:-webkit-box;
              -webkit-box-orient:vertical;
              -webkit-line-clamp:2;
              overflow: hidden;
            }
            .time{
              font-size:18px;
              color: #B5B5B5;
              font-weight: 100;
            }
          }
          &:hover{
            .news-title{
              color:rgba(253,156,2,1);
            }
          }
        }
      }
    }
  }
}
}
@media screen and (max-width: 1400px) {
  .preview-news{
    width: 100%;
    height: auto;
    background: #fff;
    .main{
      width: 100%;
      height: auto;
      display: flex;
      padding-top: 1rem;
      justify-content: space-between;
      padding:1.07rem .48rem;
      .left{
        flex: 1;
        h3{
          color: #313131;
          font-size:.48rem;
          font-weight: bolder;
          text-align: center;
        }
        .news-setting{
          color: #B5B5B5;
          display: flex;
          font-size:18px;
          .time{
            font-size:.267rem;
            color: #B5B5B5;
            font-weight: 100;
            width: 100%;
            text-align: center;
          }
          .num{
            font-size:18px;
            margin-left: 30px;
            font-weight: 100;
          }
        }
        .news-content{
          width: 100%;
          p{
            font-size: .373rem;
            color: #313131;
            line-height: .7rem;
            text-indent: .747rem;
          }
          .img-explain{
            text-align:justify;
            text-align: center;
            font-size:.267rem;
          }
        }
        .img-box{
          width: 100%;
          display: flex;
          justify-content: center;
          img{
            width:100%;
            height: 100%;
          }
        }
      }
      .pagination{
        display: flex;
        flex-direction: column;
        justify-content: center;
        margin-top: .53rem;
        .a-more{
          display: flex;
          flex-direction: column;
          font-size:.32rem;
          span{
            display: inline-block;
            width:8.933rem;
            cursor: pointer;
            color:rgba(49,49,49,1);
            height: auto;
            padding:.267rem .32rem;
            border: 1px solid #8A8684;
            border-radius: .267rem;
            white-space: nowrap;
            text-overflow:ellipsis;
            overflow: hidden;
            margin-bottom: .48rem;
          }
        }
        .el-button{
          width: 100%;
          font-size:.427rem;
          border: 1px solid rgba(253,156,2,1);
          padding:.267rem .32rem;
          border-radius: .267rem;
          color:rgba(253,156,2,1);
        }
      }
      // 搜索区域
      // .search-right{
      //   width: 334px;
      //   .el-input{
      //     width: 329px;
      //     height: 45px;
      //   }
      //   .el-input__inner{
      //     border-radius: 5px;
      //   }
      //   .el-input__icon{
      //     font-size: 24px;
      //   }
      //   .hot-news{
      //     .hot-title{
      //       font-size:24px;
      //       margin-bottom: 12px;
      //       color:#313131;
      //       .hot-icon{
      //         display: inline-block;
      //         background: url('../../assets/images/news/hot.png');
      //         width: 18px;
      //         height: 22px;
      //         margin-left: 6px;
      //       }
      //     }
      //     .dg-news{
      //       display: flex;
      //       margin-bottom: 22px;
      //       img{
      //         width:132px;
      //         height:76px;
      //       }
      //       .news-introduce{
      //         flex: 1;
      //         margin-left: 8px;
      //         display: flex;
      //         flex-direction: column;
      //         justify-content: space-between;

      //         .news-title{
      //           font-size:16px;
      //           font-weight: 600;
      //           color: #313131;
      //           box-sizing: border-box;
      //           text-overflow:ellipsis;
      //           display:-webkit-box;
      //           -webkit-box-orient:vertical;
      //           -webkit-line-clamp:2;
      //           overflow: hidden;
      //         }
      //         .time{
      //           font-size:18px;
      //           color: #B5B5B5;
      //           font-weight: 100;
      //         }
      //       }
      //       &:hover{
      //         .news-title{
      //           color:rgba(253,156,2,1);
      //         }
      //       }
      //     }
      //   }
      // }
    }
  }
}
</style>
