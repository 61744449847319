var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"preview-news"},[_c('div',{staticClass:"main"},[_c('div',{staticClass:"left"},[_c('h3',[_vm._v("中央媒体“长沙高质量发展调研行”启动")]),_vm._m(0),_vm._m(1),_vm._m(2),_vm._m(3),_vm._m(4),_vm._m(5),_c('div',{staticClass:"pagination"},[_c('div',{staticClass:"a-more"},[_c('span',{on:{"click":function($event){return _vm.$router.push('/indexOs/newsDetails10')}}},[_vm._v("上一条：两山”崛起“头脑产业” —长沙高质量发展调研行")]),_c('span',{on:{"click":function($event){return _vm.$router.push('/indexOs/newsDetails12')}}},[_vm._v("下一条：透明计算应用系列成果再出智慧医疗和智慧教育新品， 5G技术将催生更多应用场景")])]),_c('el-button',{attrs:{"plain":""},on:{"click":function($event){return _vm.backGo()}}},[_vm._v(" 返回 ")])],1)])])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"news-setting"},[_c('h3',{staticClass:"time"},[_vm._v(" 发布时间： 2019-04-28 ")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"img-box",staticStyle:{"margin-top":"40px"}},[_c('img',{attrs:{"src":require("../../assets/images/news/news11/news1.png"),"alt":""}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"img-box",staticStyle:{"margin-top":"20px"}},[_c('img',{attrs:{"src":require("../../assets/images/news/news11/news2.png"),"alt":""}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"img-box",staticStyle:{"margin-top":"20px"}},[_c('img',{attrs:{"src":require("../../assets/images/news/news11/news3.png"),"alt":""}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"img-box",staticStyle:{"margin-top":"20px"}},[_c('img',{attrs:{"src":require("../../assets/images/news/news11/news4.png"),"alt":""}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"news-content",staticStyle:{"margin":"20px 0 40px"}},[_c('p',{staticStyle:{"text-align":"justify"}},[_vm._v(" 今天，中宣部组织的中央媒体来湘，“壮丽70年，奋斗新时代，推动高质量发展调研行”主题采访活动正式启动。人民日报，新华社等12家央媒的三十多名记者将在接下来的三天里，对长沙的文化、科技、产业等全方位深入采访。 ")]),_c('p',{staticStyle:{"text-align":"justify"}},[_vm._v(" 本次主题采访活动将走访湘江新区、长沙高新区、长沙经开区以及以岳麓山国家大学科技城，马栏山视频文创产业园为代表的重点片区。用笔墨镜头突显长沙高质量发展的城市品质。 ")]),_c('p',{staticStyle:{"text-align":"justify"}},[_vm._v(" 位于岳麓山国家大学科技城的新云网科技有限公司与中南大学建立了产学研战略合作关系。去年九月，湖南成为全国首个教育信息化2.0试点省份，新云网结合教育大数据、人工智能和智慧物联三大技术，打造出教育资源、智慧课堂、在线学习、教育管理四个层面的运用，推动了教育信息化的快速发展。 ")]),_c('p',{staticStyle:{"text-align":"justify"}},[_vm._v(" 新云网打造的这种智慧教室可以通过智慧黑板，智慧课桌等信息终端，让抽象生硬的知识变得简单易懂，大大提升了学习效率和师生互动。 ")]),_c('p',{staticStyle:{"text-align":"justify"}},[_vm._v(" 近年来长沙按照省委省政府“创新引领 开放学习”战略，狠抓实体经济，发力产业项目。岳麓山国家大学科技城，马栏山视频文创产业园等重点片区高起点规划，大手笔建设。在项目孵化、产业培育、创新创业等方面取得了瞩目的成就，长沙呈现了高质量发展的喜人态势。 ")])])
}]

export { render, staticRenderFns }